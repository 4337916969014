<template>
  <div>

    <!-- <div v-model="(locale = $i18n.locale)" /> -->
    <DataTable
      :total="total"
      :allow-search="allowSearch"
      :filter="filter"
      :loading="load"
      @Refresh="refresh"
    >
      <template #action>
        <b-dropdown
          :right="!$store.state.appConfig.layout.isRTL"
          variant="primary"

        >
          <template #button-content>
            {{ $t('Global.more') }}
            <b-badge
              v-if="selectedPaths.length"
              pill variant="light-success"
            >
              {{ selectedPaths.length }} {{ $t('Global.item') }}
            </b-badge>
          </template>
          <b-dropdown-item v-if="more" @click="exportedLink()">
            <a
              :href="exported()"
              target="_blank"
            >
              <feather-icon icon="DownloadCloudIcon" />
              <span class="align-middle ml-50">{{
                $t('archive.export_file')
              }}</span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item v-if="more" @click="$refs.refInputElAll.click()">
            <feather-icon icon="FolderPlusIcon" />
            <span class="align-middle ml-50">{{
              $t('archive.Import_Files')
            }}</span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="more" />
          <b-dropdown-item v-if="more" @click="edit({})">
            <feather-icon icon="FolderIcon" />
            <span class="align-middle ml-50">{{
              $t('Global.add_folder')
            }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="$refs.refInputEl.click()">
            <feather-icon icon="UploadIcon" />
            <span class="align-middle ml-50">{{ $t('Global.upload') }}</span>
          </b-dropdown-item>
          <template v-if="selectedPaths.length">
            <b-dropdown-divider />
            <b-dropdown-item @click="paste()">
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">{{ $t('archive.paste') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="move()">
              <feather-icon icon="FolderPlusIcon" />
              <span class="align-middle ml-50">{{ $t('archive.move') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="resetSelected()">
              <feather-icon icon="RepeatIcon" />
              <span class="align-middle ml-50">{{
                $t('archive.resetItem')
              }}</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
        <input
          ref="refInputEl"
          type="file"
          class="d-none"
          @change="upload"
        >
        <input
          ref="refInputElAll"
          type="file"
          class="d-none"
          @change="uploadBackup"
        >
        <input
          ref="refInputElUpdate"
          type="file"
          class="d-none"
          @change="uploadItem"
        >
      </template>
      <template #customTable>
        <b-breadcrumb
          v-if="lookups && breadcrumb"
          class="breadcrumb-slash mb-2 font-weight-bolder font-medium-1"
        >
          <b-breadcrumb-item
            v-for="(item, i) in lookups.path"
            :key="i"
            class="text-capitalize"
            @click="navigate(item.id)"
          >
            {{ item.title }}
          </b-breadcrumb-item>
        </b-breadcrumb>
        <DragTable
          v-if="list"
          @drop="sort"
        >
          <b-table
            id="drag-index"
            class="position-relative"
            :sort-by.sync="filter.order_by"
            :sort-desc.sync="filter.sort_direction"
            :items="items"
            responsive
            :fields="fields"
            primary-key="id"
            show-empty
            :empty-text="$t('Global.empty_text')"
          >
            <template #cell(actions)="data">
              <div class="d-flex justify-content-between mx-1">
                <b-link
                  v-if="data.item.type == 1"
                  :to="{ name: 'editor', params: { id: data.item.id } }"
                >
                  <feather-icon icon="EditIcon" />
                </b-link>
                <b-link
                  v-else
                  class="disabled_all"
                >
                  <feather-icon icon="EditIcon" />
                </b-link>
                <b-link v-if="data.item.type == 2">
                  <feather-icon @click="updateItem(data.item)" icon="RefreshCwIcon" />
                </b-link>
                <b-link
                  v-else
                  class="disabled_all"
                >
                <feather-icon icon="RefreshCwIcon" />
                </b-link>
                <b-link>
                  <feather-icon
                    icon="Edit2Icon"
                    class="text-warning"
                    @click="edit(data.item)"
                  />
                </b-link>
                <b-link>
                  <feather-icon
                    v-if="selectedPaths.includes(data.item.id)"
                    icon="CheckCircleIcon"
                    class="text-success"
                    @click="selectItem(data.item.id)"
                  />
                  <feather-icon
                    v-else
                    icon="CircleIcon"
                    class="text-primary"
                    @click="selectItem(data.item.id)"
                  />
                </b-link>
                <b-link>
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger"
                    @click="remove(data.item.id)"
                  />
                </b-link>
              </div>
            </template>
            <template #cell(title)="data">
              <div
                v-if="data.item.type == 0"
                class="d-flex"
                style="cursor: pointer"
                @click="navigate(data.item.id)"
              >
                <feather-icon
                  icon="FolderIcon"
                  size="18"
                  class="text-capitalize text-primary font-weight-bold"
                />
                <div class="text-capitalize text-primary font-weight-bold">
                  {{ data.item.title }}
                </div>
                <div
                  v-if="data.item.sub_title"
                  class="text-dark font-small-2"
                >
                  ({{ data.item.sub_title }}) -
                </div>
                <div
                  v-if="data.item.short_name"
                  class="text-dark font-small-3"
                >
                  ({{ data.item.short_name }})
                </div>
              </div>
              <div
                v-else-if="data.item.type == 1"
                class="text-capitalize text-primary font-weight-bold"
                style="cursor: pointer"
              >
                <feather-icon
                  icon="LayoutIcon"
                  size="18"
                />
                {{ data.item.title }}
              </div>
              <a
                v-else
                class="text-capitalize"
                style="cursor: pointer"
                target="_blank"
                :href="data.item.url"
              >
                <feather-icon
                  icon="FileIcon"
                  size="18"
                  class="align-middle text-body"
                />
                {{ data.item.title }}
              </a>
            </template>
            <template #cell(created_at)="data">
              <div>{{ toLocalDatetime(data.item.created_at, 'en') }}</div>
            </template>
          </b-table>
        </DragTable>
        <div v-else>
          <b-row class="match-height d-flex justify-content-center">

            <b-col
              v-for="(item, i) in items"
              :key="i"
              xl="4"
              md="4"
              sm="4"
            >
              <div
                v-if="item.type == 0"
                @click="navigate(item.id)"
              >
                <statistic-card-vertical
                  :icon="item.type == 0 ? `FolderIcon` : 'FileIcon'"
                  :statistic="item.title"
                  :statistic-title="item.size"
                  color="primary"
                  style="cursor: pointer"
                />
              </div>
              <a
                v-else
                :href="item.url"
                target="_blank"
              >
                <statistic-card-vertical
                  :icon="item.type == 0 ? `FolderIcon` : 'FileIcon'"
                  :statistic="shortMyText(item.title, 20)"
                  :statistic-title="item.size"
                  color="primary"
                />
              </a>
            </b-col>
          </b-row>
        </div>
      </template>
    </DataTable>

    <b-modal
      id="archive-model"
      ref="archive_model"
      no-close-on-backdrop
      size="lg"
      centered
      title="Add Folder"
      @ok="submit"
    >
      <form
        ref="archive_form"
        @submit.stop.prevent="submit"
      >
        <div class="row">
          <div
            v-if="!is_edit"
            class="col"
          >
            <b-form-group
              label="Type"
              label-for="folder"
              invalid-feedback="Folder is required"
            >
              <v-select
                v-model="archive_form.type"
                :options="type_options"
                :reduce="val => val.value"
                required
              />
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group
              label="Title"
              label-for="address-input"
              invalid-feedback="address is required"
            >
              <b-form-input
                id="address-input"
                v-model="archive_form.title"
                required
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-group
              label="Sub Title"
              label-for="subTitle-input"
              invalid-feedback="Sub Title is required"
            >
              <b-form-input
                id="subTitle-input"
                v-model="archive_form.sub_title"
                required
              />
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group
              label="ShortName"
              label-for="subTitle-input"
              invalid-feedback="ShortName is required"
            >
              <b-form-input
                v-model="archive_form.short_name"
                required
              />
            </b-form-group>
          </div>
        </div>
        <b-form-group
          label="Description"
          label-for="description-input"
          invalid-feedback="Description is required"
        >
          <b-form-textarea
            id="description-input"
            v-model="archive_form.description"
          />
        </b-form-group>
        <b-form-group
          label="Content Type"
          label-for="content"
        >
          <v-select
            v-model="archive_form.content_type"
            :options="content_type_options"
            :reduce="val => val.value"
          />
        </b-form-group>
        <div class="row">
          <div class="col">
            <b-form-group
              label="Date"
              label-for="date-input"
              invalid-feedback="Date is required"
            >
              <b-form-datepicker v-model="archive_form.created_at" />
            </b-form-group>
          </div>
          <div class="col">
            <b-form-group
              label="Link"
              label-for="link-input"
              invalid-feedback="Link is required"
            >
              <b-form-input
                id="link-input"
                v-model="archive_form.link"
                required
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-checkbox
              v-model="archive_form.visible"
            >Visible</b-form-checkbox>
          </div>
          <div class="col">
            <b-form-checkbox
              v-model="archive_form.editable"
            >Editable</b-form-checkbox>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BLink,
  BRow,
  BBadge,
  BTable,
  BFormCheckbox,
  BDropdown,
  BFormInput,
  BFormGroup,
    BFormDatepicker,
  BFormTextarea,
  BDropdownItem,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,

  BDropdownDivider,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataTable from '@/views/components/table/DataTable'
import DragTable from '@/views/components/table/DragTable'
import { getToken } from '@/utils/auth'

export default {
  components: {
    StatisticCardVertical,
    DataTable,
    DragTable,
    BRow,
    BFormCheckbox,
    BCol,
    BLink,
    BTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormDatepicker,
    BDropdownDivider
  },
  props: {
    user: null,
    parent: null,
    list: true,
    breadcrumb:true,
    more:true,
    allowSearch:true,
    studentShow:false
  },
  data() {
    return {
      fields: [
        // {key: 'id', sortable: true,},
        // {key: 'order', sortable: true,},
        { key: 'title', sortable: true, thStyle: { width: '40%' } , label: this.$i18n.locale === 'en' ? 'Title' : 'العنوان' },
        { key: 'content_type', sortable: true, label: this.$i18n.locale === 'en' ? 'Type' : 'نوع' },
        { key: 'size', thStyle: { width: '10%' }, label: this.$i18n.locale === 'en' ? 'Size' : 'الحجم'  },
        { key: 'created_at', label: this.$i18n.locale === 'en' ? 'Date' : 'التاريخ' },
        {
          key: 'actions',
          thStyle: { width: '12%' },
          thClass: 'customAction',
          tdClass: 'customWidth',
          label: this.$i18n.locale === 'en' ? 'Actions' : 'الإجراءات',
        },
      ],
      filter: { removed: 0 },
      archive_id: null,
      locale: this.$i18n.locale,
      type_options: [
        { label: 'Folder', value: 0 },
        { label: 'Page', value: 1 },
      ],
      content_type_options: [
        {
          value: 'section',
          label: 'Section',
        },
        {
          value: 'mainSection',
          label: 'Main Section',
        },
        {
          value: 'group',
          label: 'Group',
        },
        {
          value: 'folder',
          label: 'Folder',
        },
        {
          value: 'gallery',
          label: 'Gallery',
        },
        {
          value: 'tree',
          label: 'Tree',
        },
        {
          value: 'files',
          label: 'Files',
        },
        {
          value: 'modules',
          label: 'Modules',
        },
        {
          value: 'banner',
          label: 'Banner',
        },
        {
          value: 'link',
          label: 'Link',
        },
        {
          value: 'news',
          label: 'News',
        },
        {
          value: 'sociallink',
          label: 'Footer Link',
        },
      ],
      archive_form: {},
      is_edit: false,
      selectedPaths: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      item_id:null,
    }
  },
  watch: {
    watch: {
      '$i18n.locale': function () {
        console.log("herekljlkjlkjkllkjk ? ")
        this.refresh({ limit: 10 })
      },
    },
  },
  computed: {
    ...mapGetters({
      items: 'archive/archives',
      total: 'archive/totalArchive',
      load: 'archive/archiveLoad',
      lookups: 'archive/lookups',
    }),
    current_id() {
      if (this.$store.getters['archive/currentId']) {
        return this.$store.getters['archive/currentId']
      }
      return this.archive_id ? this.archive_id : this.parent
    },
  },
  methods: {
    updateItem(item){
        if(item?.id){
            this.item_id = item.id
            this.$refs.refInputElUpdate.click()
        }else{
            this.item_id = null
        }
    },
    uploadItem(e){
        const data = new FormData()
      data.append('file', e.target.files[0])
      const id = this.item_id ? this.item_id : this.parent
      this.$store
        .dispatch('archive/update', {
          id: this.item_id ? `/${id}` : '',
          resource: data,
        })
        .then(_ => {
          this.refresh()
        })
    },
    refresh(query = {}, load = true) {
      console.log("Are we fired ! ")
      if (this.user) {
        query.user_id = this.user
      }
      if (this.current_id) {
        query.parent_id = this.current_id
      }
      if (this.studentShow && this.current_id == null){ return }
      query = { ...query, language: this.$i18n.locale, order_direction: 'ASC' }
      this.$store.dispatch('archive/archives', { query, load }).then(_ => {
        this.$store.commit('archive/SET_CURRENT_ID', null)
      })
    },
    sort(columns) {
      if (columns.first != '' && columns.second != '') {
        let query = {}
        if (columns.first.order < columns.second.order) {
          query = {
            id: columns.first.id,
            neighbour_id: columns.second.id,
            position: 'after',
          }
        } else {
          query = {
            id: columns.first.id,
            neighbour_id: columns.second.id,
            position: 'before',
          }
        }
        this.$store.dispatch('archive/order', query).then(() => {
          this.refresh({}, false)
        })
      }
    },
    navigate(archive_id) {
      let query = {
        limit: 10,
        parent_id: archive_id,
      }
      this.archive_id = archive_id
      query = { ...query, language: this.$i18n.locale, order_direction: 'ASC' }
      this.$store.dispatch('archive/archives', { query })
    },
    remove(id) {
      this.$swal({
        title: `${this.$t('Global.deleteTitle')}`,
        text: `${this.$t('Global.deleteText')}`,
        icon: 'warning',
        showCancelButton: true,
cancelButtonText:`${this.$t('Global.cancel')}`,
        confirmButtonText: `${this.$t('Global.deleteBtn')}`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('archive/delete', { id }).then(_ => {
            this.refresh({})
            this.$swal({
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
              text: this.$t('Global.deleted'),
            })
          })
        }
      })
    },
    upload(e) {
      const data = new FormData()
      data.append('file', e.target.files[0])
      const id = this.archive_id ? this.archive_id : this.parent
      this.$store
        .dispatch('archive/upload', {
          id: id ? `/${id}` : '',
          resource: data,
        })
        .then(_ => {
          this.refresh()
        })
    },
    uploadBackup(e) {
      const data = new FormData()
      data.append('file', e.target.files[0])
      const id = this.archive_id ? this.archive_id : this.parent
      this.$store
        .dispatch('archive/import', {
          id: id ? `/${id}` : '',
          resource: data,
        })
        .then(_ => {
          this.refresh()
        })
    },
    edit(item) {
      console.log('item', item)
      if (item.id) {
        this.is_edit = true
      } else {
        this.is_edit = false
      }
      this.archive_form = item
      this.$refs.archive_model.show()
    },
    submit(e) {
      e.preventDefault()
      // if (this.$refs.archive_form.checkValidity()) {
      if (this.is_edit) {
        this.$store
          .dispatch('archive/rename', {
            resource: {
              parent_id: this.current_id ? this.current_id : 0,
              language: this.$i18n.locale,
              ...this.archive_form,
            },
            id: this.archive_form.id,
          })
          .then(_ => {
            this.refresh()
            this.$refs.archive_model.toggle()
          })
      } else {
        this.$store
          .dispatch('archive/add', {
            resource: {
              parent_id: this.current_id ? this.current_id : 0,
              language: this.$i18n.locale,
              ...this.archive_form,
            },
          })
          .then(_ => {
            this.refresh()
            this.$refs.archive_model.toggle()
          })
      }

      // }
    },
    selectItem(id) {
      if (!this.selectedPaths.includes(id)) {
        this.selectedPaths.push(id)
      } else {
        for (let i = 0; i < this.selectedPaths.length; i++) {
          if (this.selectedPaths[i] === id) {
            this.selectedPaths.splice(i, 1)
          }
        }
      }
    },
    resetSelected() {
      this.selectedPaths = []
    },
    exportedLink() {
      window.open(`${this.exported()}`, '_blank')
    },
    exported() {
      const id = this.current_id ? this.current_id : 0
      return `${this.baseUrl}/archive/export/${id}?token=${getToken()}`
    },
    move() {
      this.$store
        .dispatch('archive/move', {
          marked_ids: this.selectedPaths,
          currentId: this.current_id ? this.current_id : 0,
        })
        .then(response => {
          this.refresh()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              variant: 'success',
              text: `${this.$t('Global.successMessage')}`,
            },
          })
          this.resetSelected()
        })
    },
    paste() {
      this.$store
        .dispatch('archive/paste', {
          marked_ids: this.selectedPaths,
          currentId: this.current_id ? this.current_id : 0,
        })
        .then(response => {
          this.refresh()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              variant: 'success',
              text: `${this.$t('Global.successMessage')}`,
            },
          })
          this.resetSelected()
        })
    },
  },
  mounted(){
  }
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.width-25 {
  width: 25% !important;
}

.width-50 {
  width: 50% !important;
}

.width-70 {
  width: 70% !important;
}
</style>
